<template>
  <div id="home">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      showHeader: true,
      showLogin: true,
      linksList: [],
      aboutShow: 1,
      showOtherSite: 1,
      phones: "",
    };
  },
  // created() {
  //   this.phones = localStorage.getItem("phone");
  //     //consloe.log(this.phones);
  //   // this.$store.commit("getParams");
  //   let token = localStorage.getItem("dftToken");
  //   if (token) {
  //     this.$store.commit("setIsLogin", true);
  //     // this.getUserInfo();
  //   } else {
  //     this.$store.commit("setIsLogin", false);
  //   }
  //   if (
  //     this.$route.name == "Login" ||
  //     this.$route.name == "ForgetPassword" ||
  //     this.$route.name == "Register"||
  //     this.$route.name == "Index"
  //   ) {
  //     this.showHeader = false;
  //   } else {
  //     this.showHeader = true;
  //   }
  //   if (this.$route.name == "Login") {
  //     this.showLogin = true;
  //   } else {
  //     this.showLogin = false;
  //   }
  //   // this.getLinks();
  //   if (window.location.host == "www.fabwang.com") {
  //     this.aboutShow = 2;
  //   }
  //   if (
  //     "dtk.fabwang.com;bao.hzkem.com;ruguo.fabwang.com;daixia.fabwang.com;cizhuan.fabwang.com;fd.fabwang.com".indexOf(
  //       window.location.host
  //     ) == -1
  //   ) {
  //     this.showOtherSite = 2;
  //   }
  // },
  mounted() {},
  watch: {
    $route(to, from) {
      if (
        to.name == "Login" ||
        to.name == "ForgetPassword" ||
        this.$route.name == "Register"
      ) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
      if (this.$route.name == "Login") {
        this.showLogin = true;
      } else {
        this.showLogin = false;
      }
    },
  },
  methods: {
    logout() {
      // this.$store.commit("getUserInfo", {});
      this.$store.commit("setIsLogin", false);
      this.$router.push("/login");
      localStorage.removeItem("dftToken");
      localStorage.removeItem("phone");
    },
    getUserInfo() {
      this.$request.post({
        url: "/ReportApi/business/info",
        params: {
          phone: this.phones,
        },
        success: (res) => {
          var arr = {};
          delete res.email;
          delete res.first_recharge_time;
          delete res.openid;
          delete res.parent_id;
          delete res.password;
          delete res.remark;

          delete res.total_amount;

          res.createTime = res.create_time;
          delete res.create_time;
          res.firstRechargeStatus = res.first_recharge_status;
          delete res.first_recharge_status;
          res.inviteCount = res.invite_count;
          delete res.invite_count;
          res.levelId = res.level_id;
          delete res.level_id;
          res.withdrawStatus = res.withdraw_status;
          delete res.withdraw_status;
          arr.info = res;
          if (res.levelId == 0) {
            arr.level = "普通会员";
          } else if (res.levelId == 1) {
            arr.level = "高级会员";
          } else {
            arr.level = "最高会员";
          }

          // consloe.log(arr);
          // this.$store.commit("getUserInfo", arr);
        },
      });
    },
    openQQ() {
      var url =
        "tencent://message/?uin=" +
        this.$store.state.tenantInfo.qq +
        "&amp;Site=&amp;Menu=yes";
      window.open(url, "_self");
    },
    getLinks() {
      this.$request.post({
        url: "/ReportApi/business/info",
        params: {
          phone: this.phones,
        },
        success: (res) => {
          var arr = {};
          delete res.email;
          delete res.first_recharge_time;
          delete res.openid;
          delete res.parent_id;
          delete res.password;
          delete res.remark;

          delete res.total_amount;

          res.createTime = res.create_time;
          delete res.create_time;
          res.firstRechargeStatus = res.first_recharge_status;
          delete res.first_recharge_status;
          res.inviteCount = res.invite_count;
          delete res.invite_count;
          res.levelId = res.level_id;
          delete res.level_id;
          res.withdrawStatus = res.withdraw_status;
          delete res.withdraw_status;
          arr.info = res;
          if (res.levelId == 0) {
            arr.level = "普通会员";
          } else if (res.levelId == 1) {
            arr.level = "高级会员";
          } else {
            arr.level = "最高会员";
          }

          //consloe.log(arr);
          // this.$store.commit("getUserInfo", arr);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  height: 100%;
  .header_wrap {
    min-height: 100%;
    .main {
      // padding-bottom: 200px;
      .header {
        .top_header {
          background: #f5f5f5;
          border-bottom: #eaeaea;
          a {
            color: #999;
            &:hover {
              color: #333;
            }
          }
          .wrap {
            width: 1400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 39px;
            color: #999;
            font-size: 12px;
          }
          .top_right {
            li {
              padding: 0 15px;
              border-right: 1px solid #e1e1e1;
              display: inline-block;
              cursor: pointer;
            }
          }
        }
        .center_header {
          height: 125px;
          width: 1400px;
          // width: 1265px;
          .logo {
            width: 220px;
            height: 105px;
            margin-top: 10px;
          }
          .nav {
            margin-left: 50px;
            ul {
              float: left;
              // width: 688px;
              width: 610px;
              display: block;
              margin-top: 50px;
              margin-right: 30px;
            }
            li {
              float: left;
              margin: 0 4px;
              padding: 0 4px;
              font-size: 16px;
              color: #999;
              &:hover {
                cursor: pointer;
                color: #f80;
              }
            }
            .router-link-exact-active {
              color: #f80;
            }
          }
          .head_menu_user {
            margin-top: 50px;
            img {
              margin-right: 10px;
            }
            .info {
              color: #999;
              line-height: 25px;
              font-size: 14px;
              .red {
                color: #f80;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-flex;
                max-width: 90px;
              }
              .logout {
                margin-left: 15px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .login_header {
        padding-top: 30px;
        .login_img {
          display: flex;
          align-items: center;
          div:hover {
            cursor: pointer;
          }
          p {
            color: #8c8c8c;
            margin-left: 15px;
          }
        }
        .login_text {
          line-height: 130px;
          font-size: 14px;
          a {
            color: #f80;
          }
        }
      }
    }
  }
  .footer_wrap {
    height: 200px;
    margin-top: -200px;
    padding-top: 30px;
    ul {
      li {
        display: inline-block;
        width: 270px;
        height: 56.5px;
        img {
          width: 74px;
          margin-top: -7px;
        }
        .info {
          display: inline-block;
          margin-left: 20px;
          vertical-align: top;
          .title {
            color: #333;
            font-size: 16px;
            font-weight: bold;
            line-height: 36px;
          }
          .desc {
            font-size: 12px;
            color: #999;
          }
        }
        &:not(:first-child) {
          margin-left: 33px;
        }
      }
    }
    .copyright {
      text-align: center;
      font-size: 12px;
      color: #666;
      margin-top: 48px;
      clear: both;
      .jc {
        line-height: 50px;
      }
      .link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        p {
          display: flex;
          flex-wrap: wrap;
        }
        a {
          margin-right: 6px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
